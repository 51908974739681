import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../assets/css/header.css";
import LogOut from "./logout-modal";
import { withTranslation } from "react-i18next";
import { exportCSV, getTotalOwed,updatePaidByRange } from "../redux/actions/users/management";
import { startOfMonth, endOfMonth, format } from "date-fns";
import DateRangePicker from "./DateRangePicker";
import { withRouter } from "react-router-dom";
import Papa from "papaparse";
import dropdownIcon from "../assets/images/drop-down.svg";
import spreadsheetIcon from "../assets/images/spreadsheet.svg";
import payIcon from "../assets/images/pay.svg";
import dropDownBlackIcon from "../assets/images/drop-down-black.svg"
import mobileMenuIcon from "../assets/images/mobile-menu.svg"
import downIcon from "../assets/images/down.svg"
import upIcon from "../assets/images/up.svg"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class Header extends Component {
  constructor(props) {
    super(props);
    this.tmr = null;
    this.state = {
      menuVisible: false,
      scrolling: false,
      assignVisible: false,
      userVisible: false,
      languageVisible: false,
      show: false,
      checkLang: this.props.i18n.language,
      total_owed: null,
      showTotals: localStorage.role === "admin"?false:true,
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
      allTotals: true,
      csvLoading: false,
      artistName:'',
      showPayment: false,
      paid_date: new Date(),
      detail: "",
      sendEmail: 0
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.get_total_owed &&
      this.props.get_total_owed !== prevProps.get_total_owed
    ) {
      this.setState({
        total_owed: this.props.get_total_owed,
      });
    }

    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.state.end.getTime() !== prevState.end.getTime()
    ) {
      this.getTotals();
    }

  }
  getTotals() {
    const data = {
      role_id: localStorage.getItem("id"),
    };
    const pathname = this.props.location.pathname;
    if (!this.state.allTotals || pathname.includes("/dashboard")) {
      data.range = {
        start: this.state.start,
        end: this.state.end,
      };
    }

    // const pathname = this.props.location.pathname;
    if (pathname) {
      if (pathname === "/user-list") {
      } else if (pathname.includes("/publisher/albums/")) {
        const id = pathname.split("/").pop();
        data.publisher_id = id;
      } else if (pathname.includes("/publisher/tracks/")) {
        const pathParts = pathname.split("/");
        if (pathParts.length > 0) {
          const idParts = pathParts.pop().split("-");
          if (idParts.length >= 2) {
            const userId = idParts[0];
            const albumId = idParts[1];
            data.publisher_id = userId;
            data.album_id = albumId;
          }
        }
      } else if (pathname.includes("/user-track-list/")) {
        const albumId = pathname.split("/").pop();
        data.album_id = albumId;
      }
    }

    const { getTotalOwed } = this.props;
    if (getTotalOwed) {
      getTotalOwed(data);
    }
  }
  
  showExportCSV = () => {
    const pathname = this.props.location.pathname;
    return (
      pathname.includes("/user-list") ||
      pathname.includes("/publisher/albums/") ||
      pathname.includes("/publisher/tracks/") ||
      pathname.includes("/user-reports") ||
      pathname.includes("/user-track-list/")||
      pathname.includes("/dashboard")
    );
  };
  exportDetailedCSV = async () => {
    const data = {
      role_id: localStorage.getItem("id"),
    };

    if (!this.state.allTotals) {
      data.range = {
        start: this.state.start,
        end: this.state.end,
      };
    }

    const pathname = this.props.location.pathname;
    if (pathname) {
      if (pathname.includes("/publisher/albums/")) {
        const id = pathname.split("/").pop();
        data.publisher_id = id;
      } else if (pathname.includes("/publisher/tracks/")) {
        const pathParts = pathname.split("/");
        if (pathParts.length > 0) {
          const idParts = pathParts.pop().split("-");
          if (idParts.length >= 2) {
            const userId = idParts[0];
            const albumId = idParts[1];
            data.publisher_id = userId;
            data.album_id = albumId;
          }
        }
      } else if (pathname.includes("/user-track-list/")) {
        const albumId = pathname.split("/").pop();
        data.album_id = albumId;
      }
    }

    this.setState({ csvLoading: true });
    exportCSV(data)
      .then((res) => {
        let parser = new DOMParser();
        res.forEach((item) => {
          if (item.albumName) {
            item.albumName = parser.parseFromString(
              item.albumName,
              "text/html"
            ).body.textContent;
          }
          if (item.artistName) {
            item.artistName = parser.parseFromString(
              item.artistName,
              "text/html"
            ).body.textContent;
            
          }
          if (item.trackName) {
            item.trackName = parser.parseFromString(
              item.trackName,
              "text/html"
            ).body.textContent;
          }
          if (item.publisherName) {
            item.publisherName = parser.parseFromString(
              item.publisherName,
              "text/html"
            ).body.textContent;
            if(this.state.artistName!=item.artistName){
              this.setState({artistName: item.publisherName});
            }
          }
        });
    
        let csv = Papa.unparse(res);
        let name=''
        if(pathname.includes("/publisher/albums/") ||
        pathname.includes("/publisher/tracks/"))
        {name=this.state.artistName+'-'+ this.getDateRange() + "report.csv";}
        else{
          name=this.getDateRange() + "report.csv";
        }
        this.downloadCSV(csv, name);
      })
      .catch((err) => {})
      .finally(() => {
        this.setState({ csvLoading: false });
      });
  };
  getDateRange() {
    const { start, end, allTotals } = this.state;

    if (!allTotals) {
      const startDate = format(start, "yyyy-MM-dd");
      const endDate = format(end, "yyyy-MM-dd");

      return `${startDate}-${endDate}-`;
    }

    return "";
  }

  downloadCSV = (csv, filename) => {
    let csvFile;
    let downloadLink;
    csvFile = new Blob(["\ufeff" + csv], {
      type: "text/csv;charset=charset=utf-8",
    });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  assignMenu = () => {
    this.setState({
      assignVisible: !this.state.assignVisible,
    });
    if (!this.state.assignVisible) {
      this.setState({
        userVisible: false,
        languageVisible: false,
      });
    }
    this.setState({
      menuVisible: false,
    });
  };
  toggleMenu = () => {
    this.setState({
      menuVisible: !this.state.menuVisible,
    });
  };
  userMenu = () => {
    this.setState({
      userVisible: !this.state.userVisible,
    });
    if (!this.state.userVisible) {
      this.setState({
        assignVisible: false,
        languageVisible: false,
      });
    }
  };
  languageMenu = () => {
    this.setState({
      languageVisible: !this.state.languageVisible,
    });
    if (!this.state.languageVisible) {
      this.setState({
        assignVisible: false,
        userVisible: false,
      });
    }
  };
  componentDidMount() {
    if (!localStorage.id) {
      window.location.href = "/login";
    }
    window.addEventListener("scroll", this.handleScroll, true);

    const pathname = this.props.location.pathname;
    if(pathname.includes("/dashboard"))
      {this.setState({allTotals:false})}

    if (!this.props.get_total_owed) {
      this.getTotals();
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    if (window.scrollY > 50) {
      this.setState({ scrolling: true });
    } else {
      this.setState({ scrolling: false });
    }
  };
  onLink = () => {
    this.setState({
      menuVisible: false,
      assignVisible: false,
      userVisible: false,
    });
  };
  showModal = () => {
    this.setState({
      show: true,
    });
  };
  hideModal = () => {
    this.setState({
      show: false,
    });
  };
 
  /**
   * Language
   */
  handleChange = (newlang) => {
    this.setState((prevState) => ({ checkLang: newlang }));
    this.props.i18n.changeLanguage(newlang);
  };
  onChangeLanguage = (key) => {
    this.handleChange(key);
    this.setState({
      languageVisible: false,
    });
  };

  formatTotal = (number) => {
    if (!number) {
      return 0;
    }

    const formattedNumber = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  };

 // payment 
 showPaymentModal = () => {
  this.setState({
    showPayment: true,
  });
};
hidePaymentModal = () => {
  this.setState({
    showPayment: false,
    detail: "",
    paid_date: new Date(),
    sendEmail:0,
  });
};
handleDate(date) {
  this.setState({
    paid_date: date,
  });
}
updatePaid = async () =>{
  const data = {
    role_id: localStorage.getItem("id"),
    comment: this.state.detail,
    paid_date: this.state.paid_date,
    sendEmail: this.state.sendEmail
  };

  if (!this.state.allTotals) {
    data.range = {
      start: this.state.start,
      end: this.state.end,
    };
  }

  const pathname = this.props.location.pathname;
  if (pathname) {
    if (pathname.includes("/publisher/albums/")) {
      const id = pathname.split("/").pop();
      data.publisher_id = id;
    } 
  }  
  const { updatePaidByRange } = this.props;
  if (updatePaidByRange) {
     updatePaidByRange(data);
  }
  this.hidePaymentModal();
  
}

  render() {
    const { t } = this.props;
    const { start, end, csvLoading } = this.state;
    return (
      <div
        style={{
          zIndex: 3000,
          position: "absolute",
        }}
      >
        <div className={`header-body ${this.state.scrolling ? "white" : ""}`}>
          <div
            className={`header-height ${this.state.scrolling ? "white" : ""}`}
          >
            <div className="logo-mw flex-space">
              <div className={"header-logo"}>
                <div
                  className="col-selected-bg txt-bold flex-center"
                  style={{ padding: "10px" }}
                >
                  <div
                    className="btn-underLine"
                    // to={
                    //   localStorage.role === "admin"
                    //     ? "/user-list"
                    //     : "/user/reports"
                    // }
                    onClick={() => {
                      this.setState({ showTotals: !this.state.showTotals });
                    }}
                  >
                    {t("hi")} {localStorage.name}{" "}
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ showTotals: !this.state.showTotals });
                    }}
                    style={
                      this.state.showTotals
                        ? { transform: "rotate(-180deg)" }
                        : {}
                    }
                    className="mouse-cursor"
                  >
                    {this.state.scrolling ? (
                      <img
                        className="drop-icon"
                        src={dropDownBlackIcon}
                        style={
                          this.state.showTotals
                            ? { paddingRight: 10, paddingLeft: 0 }
                            : {}
                        }
                        // alt="drop down"
                      />
                    ) : (
                      <img
                        className="drop-icon"
                        src={dropdownIcon}
                        style={
                          this.state.showTotals
                            ? { paddingRight: 10, paddingLeft: 0 }
                            : {}
                        }
                        // alt="drop down"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="justify-center">
                <div className="flex-center">
                  {localStorage.role === "admin" && (
                    <>
                      <div className="btn-navbar mouse-cursor dropdown">
                        <span>{t("user")}</span>
                        {this.state.scrolling ? (
                          <img
                            className="drop-icon"
                            src={dropDownBlackIcon}
                            alt="drop down"
                          />
                        ) : (
                          <img
                            className="drop-icon"
                            src={dropdownIcon}
                            alt="drop down"
                          />
                        )}
                        <div className="dropdown-content">
                          <Link className="btn-underLine" to="/add-user">
                            <div className="menu-txt mouse-cursor">
                              {t("add_user")}
                            </div>
                          </Link>
                          <Link className="btn-underLine" to="/user-list">
                            <div className="menu-txt mouse-cursor">
                              {t("user_list")}
                            </div>
                          </Link>
                        </div>
                      </div>
                      <Link className="btn-underLine" to="/add-album">
                        <div className="btn-navbar mouse-cursor">
                          {t("new_album")}
                        </div>
                      </Link>
                      <Link className="btn-underLine" to="/assign-album">
                        <div className="btn-navbar mouse-cursor">
                          {t("assign_to_user")}
                        </div>
                      </Link>
                      <Link className="btn-underLine" to="/dashboard">
                        <div className="btn-navbar mouse-cursor">
                          {t("dashboard")}
                        </div>
                      </Link>
                    </>
                  )}

                  {localStorage.role === "publisher" && (
                    <>
                      <Link className="btn-underLine" to="/user-agreement">
                        <div className="btn-navbar mouse-cursor">
                          {t("payment")}
                        </div>
                      </Link>
                      <Link className="btn-underLine" to="/user-reports">
                        <div className="btn-navbar mouse-cursor">
                          {t("report")}
                        </div>
                      </Link>
                      <Link className="btn-underLine" to="/user-settings">
                        <div className="btn-navbar mouse-cursor">
                          {t("profile_settings")}
                        </div>
                      </Link>
                    </>
                  )}

                  <div className="btn-navbar mouse-cursor dropdown">
                    <span>{t("language")}</span>
                    {this.state.scrolling ? (
                      <img
                        className="drop-icon"
                        src={dropDownBlackIcon}
                        alt="drop down"
                      />
                    ) : (
                      <img
                        className="drop-icon"
                        src={dropdownIcon}
                        alt="drop down"
                      />
                    )}
                    <div className="dropdown-content">
                      <div
                        className="btn-underLine"
                        onClick={() => this.handleChange("en")}
                      >
                        <div className="menu-txt mouse-cursor">
                          <label className="container-event language">
                            {t("english")}
                            <input
                              type="checkbox"
                              defaultChecked={this.state.checkLang === "en"}
                            />
                            <span className="checkMark" />
                          </label>
                        </div>
                      </div>
                      <div
                        className="btn-underLine"
                        onClick={() => this.handleChange("he")}
                      >
                        <div className="menu-txt mouse-cursor">
                          <label className="container-event language">
                            {t("hebrew")}
                            <input
                              type="checkbox"
                              defaultChecked={this.state.checkLang === "he"}
                            />
                            <span className="checkMark" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link className="btn-underLine" 
                  to={
                      localStorage.role === "admin"
                        ? "/user-list"
                        : "/user/reports"
                    }>
                        <div className="btn-navbar mouse-cursor">
                          {t("home")}
                        </div>
                      </Link>
                  <div
                    className="btn-underLine btn-navbar mouse-cursor btn-sign"
                    onClick={this.showModal}
                  >
                    {t("log_out")}
                  </div>
                </div>
                <div
                  id="menu"
                  className="justify-center"
                  onClick={this.toggleMenu}
                >
                  <img
                    className="mobile-menu mouse-cursor"
                    src={mobileMenuIcon}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.menuVisible &&
            (localStorage.role === "admin" ? (
              <div
                className="menu-container trans-menu"
                style={{ overflowY: "auto;", maxHeight: "70vh" }}
              >
                <div className="btn-underLine">
                  <div
                    className="navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                    onClick={this.logOut}
                  >
                    {t("log_out")}
                  </div>
                </div>
                <div
                  className={
                    this.state.languageVisible
                      ? "navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb menu-selectedBg"
                      : "navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                  }
                  onClick={this.languageMenu}
                >
                  {t("language")}
                  {this.state.languageVisible ? (
                    <div className="dropdown-icon justify-center">
                      <img
                        className="down-up-size"
                        src={upIcon}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="dropdown-icon">
                      <img
                        className="down-up-size"
                        src={downIcon}
                        alt=""
                      />
                    </div>
                  )}
                </div>
                {this.state.languageVisible && (
                  <div>
                    <div
                      className="btn-underLine_black secondMenu justify-center menu-txt btn-navbar-bb mouse-cursor"
                      onClick={() => this.onChangeLanguage("en")}
                    >
                      <div>{t("english")}</div>
                    </div>
                    <div
                      className="btn-underLine_black secondMenu justify-center menu-txt btn-navbar-bb mouse-cursor"
                      onClick={() => this.onChangeLanguage("he")}
                    >
                      <div>{t("hebrew")}</div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.state.userVisible
                      ? "navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb menu-selectedBg"
                      : "navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                  }
                  onClick={this.userMenu}
                >
                  {t("user")}
                  {this.state.userVisible ? (
                    <div className="dropdown-icon justify-center">
                      <img
                        className="down-up-size"
                        src={upIcon}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div className="dropdown-icon">
                      <img
                        className="down-up-size"
                        src={downIcon}
                        alt=""
                      />
                    </div>
                  )}
                </div>
                {this.state.userVisible && (
                  <div>
                    <Link
                      to="/add-user"
                      className="btn-underLine_black secondMenu justify-center menu-txt btn-navbar-bb mouse-cursor"
                      onClick={this.onLink}
                    >
                      <div>{t("add_user")}</div>
                    </Link>
                    <Link
                      to="/user-list"
                      className="btn-underLine_black secondMenu justify-center menu-txt btn-navbar-bb mouse-cursor"
                      onClick={this.onLink}
                    >
                      <div>{t("user_list")}</div>
                    </Link>
                  </div>
                )}
                <Link className="btn-underLine" to="/add-album">
                  <div
                    className="navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                    onClick={this.onLink}
                  >
                    {t("new_album")}
                  </div>
                </Link>
                <Link className="btn-underLine" to="/assign-album">
                  <div
                    className={
                      this.state.assignVisible
                        ? "navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb menu-selectedBg"
                        : "navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                    }
                    onClick={this.assignMenu}
                  >
                    {t("assign_to_user")}
                  </div>
                </Link>
                <Link className="btn-underLine" to="/dashboard">
                        <div className="navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb" 
                        onClick={this.onLink}>
                          {t("dashboard")}
                        </div>
                      </Link>
              </div>
            ) : (
              <div
                className="menu-container trans-menu"
                style={{ overflowY: "auto;", maxHeight: "70vh" }}
              >
                <div className="btn-underLine">
                  <div
                    className="navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                    onClick={this.logOut}
                  >
                    {t("log_out")}
                  </div>
                </div>

                <Link className="btn-underLine" to="/user-agreement">
                  <div
                    className="navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                    onClick={this.onLink}
                  >
                    {t("payment")}
                  </div>
                </Link>
                <Link className="btn-underLine" to="/user-reports">
                  <div
                    className="navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                    onClick={this.assignMenu}
                  >
                    {t("report")}
                  </div>
                </Link>
                <Link className="btn-underLine" to="/user-settings">
                  <div
                    className="navbar-menu justify-center txt-24 mouse-cursor btn-navbar-bb"
                    onClick={this.assignMenu}
                  >
                    {t("profile_settings")}
                  </div>
                </Link>
              </div>
            ))}
          {/*  Modal  */}
          <LogOut show={this.state.show} handleClose={this.hideModal} />
          {this.state.showTotals ? ( // && this.state.total_owed && this.state.total_owed.length
            <div
              className={localStorage.role === "publisher"?"col-paragraphBg txt-bold flex-center txt-20":"col-paragraphBg txt-bold flex-center"}
              
              style={{
                justifyContent: "space-between",
                paddingLeft: 50,
                paddingRight: 50,
                borderBottomColor: "#217346",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                position: "relative",
                backgroundColor: "#fff",
                flexWrap: "wrap",
              }
              }
             
            >
              <div
                className="spinning-curtain-small"
                style={{
                  display: this.props.totals_spinning ? "flex" : "none",
                }}
              >
                <div className="lds-dual-ring-small" />
              </div>
              <DateRangePicker
                start={start}
                end={end}
                onChangeStart={(start) => this.setState({ start })}
                onChangeEnd={(end) => this.setState({ end , paid_date:end})}
                checked={this.state.allTotals}
                onCheckChange={() => {
                  this.setState({ allTotals: !this.state.allTotals }, () => {
                    this.getTotals();
                  });
                }}
              />
              <div>
                <span style={{ marginRight: "10px" }}>{t("owed")}:</span>
                {this.state.total_owed &&
                  Object.keys(this.state.total_owed).length &&
                  Object.keys(this.state.total_owed).map((id, index) => {
                    if (id === "unset" || id === "null") {
                      return;
                    }

                    return (
                      <span key={index} style={{ marginRight: "10px" }}>
                        {this.formatTotal(this.state.total_owed[id]) +
                          (id !== "unset" ? id : "") +
                          (Object.keys(this.state.total_owed).length > index + 1
                            ? ","
                            : "")}
                      </span>
                    );
                  })}
                {this.showExportCSV() && (
                  <>
                    {csvLoading ? (
                      <div
                        className="spinning-curtain-small"
                        style={{
                          display: csvLoading ? "inline-block" : "none",
                          position: "relative",
                          width: "unset",
                          marginLeft: "10px",
                          borderRadius: "50%",
                        }}
                      >
                        <div className="lds-dual-ring-small" />
                      </div>
                    ) : (
                      <>
                        {/* <div
                          className="ml-10 mouse-cursor tooltip"
                          onClick={(e) => {}}
                          style={{ display: "inline-block" }}
                        >
                          <img
                            className="icon-size-small"
                            src={require("../assets/images/spreadsheet2.svg")}
                            alt=""
                          />
                          <span className="tooltiptext">
                            {t("export_simple_csv")}
                          </span>
                        </div> */}
                        <div
                          className="ml-10 mouse-cursor tooltip"
                          onClick={(e) => {
                            this.exportDetailedCSV();
                          }}
                          style={{ display: "inline-block" }}
                        >
                          <img
                            className={localStorage.role === "publisher"?"icon-size mb-id-10 mt-id-10":"icon-size-small"}
                            src={spreadsheetIcon}
                            alt=""
                          />
                          <span className="tooltiptext">
                            {t("export_detailed_csv")}
                          </span>
                        </div>
                      </>
                    )}

                  </>
                )}
                {this.props.location.pathname.includes("/publisher/albums/") && localStorage.role === "admin" && (
                 <div
                    className="ml-10 mouse-cursor tooltip"
                    onClick={(e) => {
                      this.showPaymentModal();
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <img
                      className={"icon-size mb-id-10 mt-id-10"}
                      src={payIcon}
                      alt=""
                    />
                    <span className="tooltiptext">
                      {t("pay_now")}
                    </span>
                  </div>
                  )}
              </div>
            </div>
          ) : null}
        </div>
        {this.state.showPayment && 
        <div className="modal-b display-modal-block">
                <section className="modal-article">
                    <div className="create-modal-header txt-20 justify-left col-white">{t("payment")}</div>
                    <div className="txt-16" style={{ padding: 30 }}>
                    {t("total_amount") + ": "}
                    {this.state.total_owed &&
                      Object.keys(this.state.total_owed).length &&
                      Object.keys(this.state.total_owed).map((id, index) => {
                        if (id === "unset" || id === "null") {
                          return;
                        }

                        return (
                          <span key={index} style={{ marginRight: "10px", color: "red" }}>
                            {this.formatTotal(this.state.total_owed[id]) +
                              (id !== "unset" ? id : "") +
                              (Object.keys(this.state.total_owed).length > index + 1
                                ? ","
                                : "")}
                          </span>
                        );
                      })}
                      {/* <div className="pt-20 pb-10">{t("paid_date")}</div> */}
                      {/* <div>
                        <DatePicker
                          id="paid_date"
                          type="datetime-local"
                          showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          className="price-paid"
                          selected={this.state.paid_date}
                          maxDate={new Date().getTime()}
                          timeIntervals="20"
                          onChange={(e) => this.handleDate(e)}
                        />
                      </div> */}
                      <div className="pt-20 pb-10">{t("payment_comments")}</div>
                      <textarea
                        id="detail"
                        value={this.state.detail}
                        className="payment-detail"
                        onChange={(e) => this.setState({ detail: e.target.value })}
                      />
                      <div className="pt-20 pb-10">{t("send_email")}</div>
                      <select
                          className="publisher mouse-cursor"
                          value={this.state.sendEmail}
                          onChange={(e) => this.setState({ sendEmail: e.target.value})}
                      >
                          <option key="0" value={0}>{t("no_send")}</option>
                          <option key="1" value={1} >{t("english")}</option>
                          <option key='2' value={2}>{t("hebrew")}</option>
                      </select>
                    </div>
                    <div className="flex-grid2 modal-grid2-gaps modal-p">
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={this.hidePaymentModal}>{t("cancel")}</div>
                        <div className="btn-common mouse-cursor justify-center col-white" onClick={() => this.updatePaid()}>{t("ok")}</div>
                    </div>
                </section>
            </div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    get_total_owed: state.users.get_total_owed,
    msg_total_owed: state.users.msg_total_owed,
    totals_spinning: state.users.totals_spinning,
  };
};
export default connect(mapStateToProps, { getTotalOwed,updatePaidByRange })(
  withTranslation("translations")(withRouter(Header))
);
