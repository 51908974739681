/**
 * The file for track management
 */
import axios from "axios";
import config from "../../../config/index";
import {
  SHOW_SPINNING,
  GET_TRACK_LIST,
  ERROR_TRACK_LIST,
  TRACK_UPDATE_PAYMENT,
  UPDATE_PAID,
  DELETE_HISTORY,
  DELETE_HISTORY_ERROR,
  GET_SEARCH_ALBUMS,
  MSG_SEARCH_ALBUMS,
  MSG_TOTAL_OWED,
  GET_TOTAL_OWED,
  SHOW_TOTALS_SPINNING,
  ERROR_PLAY_LIST,
  GET_PLAY_LIST,
  ERROR_GET_MOST_PLAYED,
  GET_MOST_PLAYED,
  GET_REVENUECAT,
  ERROR_GET_REVENUECAT
} from "../types/types";

const API_URL = config.IS_DEV ? config.DEV_API_URL : config.SIM_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  // timeout: 15000,
  headers: { Authorization: localStorage.getItem("token") },
});

export const reset = () => (dispatch) => {
  dispatch({
    type: ERROR_TRACK_LIST,
    payload: "",
  });
  dispatch({
    type: TRACK_UPDATE_PAYMENT,
    payload: "",
  });
  dispatch({
    type: MSG_SEARCH_ALBUMS,
    payload: "",
  });
};

export const resetManagement = () => (dispatch) => {
  dispatch({
    type: UPDATE_PAID,
    payload: "",
  });
  dispatch({
    type: DELETE_HISTORY_ERROR,
    payload: "",
  });
  dispatch({
    type: DELETE_HISTORY,
    payload: "",
  });
};
/**
 * Getting the track list by publisher_id and album_id
 */
export const getTrackListById = (data) => (dispatch) => {
  dispatch({ type: SHOW_SPINNING, payload: true });
  axios
    .post(API_URL + "/api/managements/get-tracks", data)
    .then((res) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: GET_TRACK_LIST,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: ERROR_TRACK_LIST,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });
};
/**
 * Updating the payment per track
 */
export const updateTrackPrice = (data) => (dispatch) => {
  dispatch({ type: SHOW_SPINNING, payload: true });
  axios
    .post(API_URL + "/api/managements/update-track", data)
    .then((res) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: TRACK_UPDATE_PAYMENT,
        payload: res.data.msg,
      });
    })
    .catch((err) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: TRACK_UPDATE_PAYMENT,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });
};
/**
 * Updating the payment per album
 */
export const updateAlbumPrice = (data) => (dispatch) => {
  dispatch({ type: SHOW_SPINNING, payload: true });
  axios
    .post(API_URL + "/api/managements/update-price-per-album", data)
    .then((res) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: TRACK_UPDATE_PAYMENT,
        payload: res.data.msg,
      });
    })
    .catch((err) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: TRACK_UPDATE_PAYMENT,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });
};
/**
 * Updating the payment per publisher
 */
export const updatePublisherPrice = (data) => (dispatch) =>{
  dispatch({ type: SHOW_SPINNING, payload: true});
  axios.post(API_URL+"/api/managements/update-price-per-publisher",data).then((res) => {
    dispatch({ type:SHOW_SPINNING, payload:false});
    dispatch({
      type: TRACK_UPDATE_PAYMENT,
      payload: res.data.msg,
    });
  })
  .catch((err) =>{
    dispatch({type:SHOW_SPINNING,payload:false});
    dispatch({
      type: TRACK_UPDATE_PAYMENT,
      payload:err.response ? err.response.data.msg :{error: "error",}
    });
  });
}


/**
 * Updating the paid amount
 */
export const updatePaid = (data) => (dispatch) => {
  dispatch({ type: SHOW_SPINNING, payload: true });
  axios
    .post(API_URL + "/api/managements/update-paid", data)
    .then((res) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: UPDATE_PAID,
        payload: res.data.msg,
      });
    })
    .catch((err) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: UPDATE_PAID,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });
};
export const updatePaidByRange = (data) => (dispatch) => {
  dispatch({ type: SHOW_SPINNING, payload: true });
  axios
   .post(API_URL + "/api/managements/update-paid-by-range", data)
   .then((res) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: UPDATE_PAID,
        payload: res.data.msg,
      });
    })
   .catch((err) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: UPDATE_PAID,
        payload: err.response? err.response.data.msg : { error: "error" },
      });
    });
};

/**
 * Cancel one paid
 */
export const cancelPaid = (data) => (dispatch) => {
  // dispatch({ type: SHOW_SPINNING, payload: true });
  axios
    .post(API_URL + "/api/managements/cancel-paid", data)
    .then((res) => {
      // dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: UPDATE_PAID,
        payload: res.data.msg,
      });    
    })
    .catch((err) => {
      // dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: UPDATE_PAID,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });
};

export const editPayments = (data) => (dispatch) =>{
  axios
   .post(API_URL + "/api/managements/edit-payment", data)
   .then((res) => {
    // dispatch({ type: SHOW_SPINNING, payload: false });
     dispatch({
       type: UPDATE_PAID,
       payload: res.data.results,
     });
   })
    .catch((err) => {
      // dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: UPDATE_PAID,
        payload: err.response? err.response.data.msg : { error: "error" },
      });
    });
}
/**
 * Extra to get the call from the existing site
 */
export const addPaymentInfo = (data) => (dispatch) => {
  dispatch({ type: SHOW_SPINNING, payload: true });
  instance
    .post("/api/records/add-played-track", data)
    .then((res) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: TRACK_UPDATE_PAYMENT,
        payload: res.data.msg,
      });
    })
    .catch((err) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: TRACK_UPDATE_PAYMENT,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });
};
/**
 * Extra to get the call from the existing site
 */
export const deleteHistory = (data) => (dispatch) => {
  dispatch({ type: SHOW_SPINNING, payload: true });
  instance
    .post("/api/managements/delete-history", data)
    .then((res) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: DELETE_HISTORY,
        payload: res.data.msg,
      });
    })
    .catch((err) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: DELETE_HISTORY_ERROR,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });
};
/**
 * Searching the albums
 */
export const searchAlbum = (data) => (dispatch) => {
  dispatch({ type: SHOW_SPINNING, payload: true });
  instance
    .post("/api/managements/search-album", data)
    .then((res) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: GET_SEARCH_ALBUMS,
        payload: res.data.results,
      });
    })
    .catch((err) => {
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: MSG_SEARCH_ALBUMS,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });
};
/**
 * Get total owed
 */
export const getTotalOwed = (data) => (dispatch) => {
  dispatch({ type: SHOW_TOTALS_SPINNING, payload: true });
  instance
    .post("/api/managements/total-owed", data)
    .then((res) => {
      dispatch({ type: SHOW_TOTALS_SPINNING, payload: false });
      dispatch({
        type: GET_TOTAL_OWED,
        payload: res.data.total_owed,
      });
    })
    .catch((err) => {
      dispatch({ type: SHOW_TOTALS_SPINNING, payload: false });
      dispatch({
        type: MSG_TOTAL_OWED,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    });

    // instance
    //     .post("/api/managements/migrate-details", data)
    //     .then(res => {
    //         console.log(res)
    //     }).catch(err => {
    // })

  // let data = {
  //   album_id: "92912", // replace with actual album_id
  //   track_url: "/singles/benny-friedman---yama.mp3	", // replace with actual track_url
  //   platform: "Android", // replace with actual platform
  //   version: "sample_version", // replace with actual version
  //   subscribed: false, // replace with actual subscribed status
  //   user_id: "sample_user_id", // replace with actual user_id
  //   // country: "US"
  // };

  // let data = {
  //   album_id: "110",
  //   track_url: "/singles/benny-friedman---yama.mp3",
  //   platform: "Android",
  //   version: "79",
  //   iat: 1687287582,
  //   exp: 1687288182,
  //   aud: "https://royalties.jewishmusic.fm",
  //   sub: "jewishmusic.fm@gmail.com",
  //   iss: "Track Loyalty",
  // };

  // instance
  //   .post("/api/logs/add-played-track", data, {
  //     headers: {
  //       Authorization: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhbGJ1bV9pZCI6IjExMCIsInRyYWNrX3VybCI6Ii9zaW5nbGVzL2Jlbm55LWZyaWVkbWFuLS0teWFtYS5tcDMiLCJwbGF0Zm9ybSI6IkFuZHJvaWQiLCJ2ZXJzaW9uIjoiNzkiLCJpYXQiOjE2ODcyODk1NzYsImV4cCI6MTY4NzI5MDE3NiwiYXVkIjoiaHR0cHM6Ly9yb3lhbHRpZXMuamV3aXNobXVzaWMuZm0iLCJzdWIiOiJqZXdpc2htdXNpYy5mbUBnbWFpbC5jb20iLCJpc3MiOiJUcmFjayBMb3lhbHR5In0.Z1r0qbC-5SrgItau7YR82dSehb3Ax6oxvReFYJR_4ihgX8pNWZfGF1iScOT6T1g8YENQMrzapxcbP2BZPTbKXzkkNjhkWxP7uU0nizzYoR1Ve6NIbuFQCmguFWqXt0Ta65i_Z1y0wgzyyt8RVruQxNCJOC60prLGGWlr3FyqsKw",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //   .then((res) => {
  //     console.log(res);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
};

export const exportCSV = (data) => {
  // dispatch =>
  return new Promise((resolve, reject) => {
    instance
      .post("/api/managements/export-csv", data)
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const exportCSVPlayed = (data) => {
  return new Promise((resolve, reject)=>{
    instance
     .post("/api/managements/export-csv-played", { data })
      .then((res) => {
        resolve(res.data.result);
      })
      .catch((err) => {
        reject(err);
      });
  })
};

export const getPlaysList = (data) => (dispatch) => {
  
  dispatch({ type: SHOW_SPINNING, payload: true });
  axios.post(API_URL+"/api/managements/played-information",data)
    .then((res)=>{
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: GET_PLAY_LIST,
        payload: res.data,
      });
    })
    .catch((err)=>{
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: ERROR_PLAY_LIST,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    })
}
export const getMostPlayedTrack = (data) => (dispatch) => {
  
   dispatch({ type: SHOW_SPINNING, payload: true });
  axios.post(API_URL+"/api/managements/get-most-played-track",data)
    .then((res)=>{
       dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: GET_MOST_PLAYED,
        payload: res.data,
      });
    })
    .catch((err)=>{
      dispatch({ type: SHOW_SPINNING, payload: false });
      dispatch({
        type: ERROR_GET_MOST_PLAYED,
        payload: err.response ? err.response.data.msg : { error: "error" },
      });
    })

  
}

export const getRevenueCatData=(data)=>(dispatch)=>{
     const prujectId=process.env.REACT_APP_REVENUE_PROJECT_ID;
     const apiKey=process.env.REACT_APP_REVENUE_SECRET_API_KEY;      
    axios.get(`https://api.revenuecat.com/v2/projects/${prujectId}/metrics/overview`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      },
    })
    .then((res)=>{
      dispatch({
        type:GET_REVENUECAT,
        payload:res.data})
    })
    .catch((err)=>{
      dispatch({
        type:ERROR_GET_REVENUECAT,
        payload:err
      })
    })
}
